import React, { FC } from "react";
import { Grid } from "@mui/material";

import { Case } from "auditaware-types";

import CaseOverview from "../Case/CaseOverview";
import CaseNotes from "../Case/CaseNotes";
import CaseAttachments from "../Case/CaseAttachments";
import CasesTable from "./CasesTable";
import CaseResults from "../Case/CaseResults";
import CaseFees from "../Case/CaseFees";


type CaseDetailProps = {
  case?: Case;
  cases?: Case[];
};

const CaseDetails: FC<CaseDetailProps> = ({ case: caseData, cases = [] }) => {
  if (!caseData) {
    return null;
  }

  const { id: caseId } = caseData;

  return (
    <>
      <CaseOverview caseData={caseData} />
      <Grid container spacing={2}>
        <Grid item xs md={6} lg={6}>
          <CaseNotes caseId={caseId} notes={caseData.notes} />
        </Grid>
        <Grid item xs md={6} lg={6}>
          <CaseAttachments caseId={caseId} attachments={caseData.attachments} />
        </Grid>
      </Grid>
      <CasesTable cases={cases}/>
      <Grid container spacing={2} marginTop={1} >
        <Grid item xs md={8} lg={8}>
          <CaseResults caseData={caseData} />
        </Grid>
        <Grid item xs md={4} lg={4}>
          <CaseFees caseData={caseData} />
        </Grid>
      </Grid>
    </>
  );
};

export default CaseDetails;
